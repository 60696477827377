import { cn } from "@/lib/utils";
import React from "react";
import { FaStar } from "react-icons/fa6";
const DisplayRatings = ({
  rating,
  iconClassName,
  halfRatingClassName,
  className
}: {
  rating: number;
  iconClassName?: string;
  halfRatingClassName?: string;
  className?: string;
}) => {
  if (rating === 0) {
    return null;
  }
  const renderStar = (index: number) => {
    if (rating >= index + 1) {
      return <FaStar className={cn("text-star size-[13px]", iconClassName)} />;
    } else if (rating >= index + 0.5) {
      return <span className={cn("text-star text-xs", halfRatingClassName)}>
          1/2
        </span>;
    }
  };
  return <div className={cn("flex items-center gap-[3px] w-fit", className)} data-sentry-component="DisplayRatings" data-sentry-source-file="DisplayRatings.tsx">
      {[...Array(rating ? Math.ceil(rating) : 5)].map((_, index) => renderStar(index))}
    </div>;
};
export default DisplayRatings;