import {
  LikeListCommentDocument,
  LikeListDocument,
  LikeReviewCommentDocument,
  LikeReviewDocument,
  UnlikeListCommentDocument,
  UnlikeListDocument,
  UnlikeReviewCommentDocument,
  UnlikeReviewDocument,
} from "@/graphql/generated/types"
import { useMutation } from "@apollo/client"
export const useLikeResource = ({
  resourceType,
  resourceId,
  likedByMe,
  likesCount,
}: {
  likedByMe: boolean
  likesCount: number
  resourceId: string
  resourceType: "review" | "reviewComment" | "list" | "listComment"
}) => {
  const [likeReview] = useMutation(LikeReviewDocument)
  const [unlikeReview] = useMutation(UnlikeReviewDocument)
  const [likeReviewComment] = useMutation(LikeReviewCommentDocument)
  const [unlikeReviewComment] = useMutation(UnlikeReviewCommentDocument)

  const [likeList] = useMutation(LikeListDocument)
  const [unlikeList] = useMutation(UnlikeListDocument)
  const [likeListComment] = useMutation(LikeListCommentDocument)
  const [unlikeListComment] = useMutation(UnlikeListCommentDocument)

  // const readReviewFromCache = () => {
  //   try {
  //     const data = client.readFragment({
  //       id: client.cache.identify({ __typename: "Review", id: resourceId }),
  //       fragment: ReviewFieldsFragmentDoc,
  //       fragmentName: "ReviewFields",
  //     })
  //     console.log(data, "data")
  //     if (data) {
  //       return {
  //         likedByMe: data.likedByMe,
  //         likesCount: data.likesCount,
  //       }
  //     }
  //     return {
  //       likedByMe: false,
  //       likesCount: 0,
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     return {
  //       likedByMe: false,
  //       likesCount: 0,
  //     }
  //   }
  // }

  // const readReviewCommentFromCache = () => {
  //   const data = client.readFragment({
  //     id: client.cache.identify({
  //       __typename: "ReviewComment",
  //       id: resourceId,
  //     }),
  //     fragment: ReviewCommentFieldsFragmentDoc,
  //     fragmentName: "ReviewCommentFields",
  //   })

  //   if (data) {
  //     return {
  //       likedByMe: data.likedByMe,
  //       likesCount: data.likesCount,
  //     }
  //   }
  //   return {
  //     likedByMe: false,
  //     likesCount: 0,
  //   }
  // }

  const toggleLike = async () => {
    try {
      if (resourceType === "review") {
        // const { likedByMe, likesCount } = readReviewFromCache()

        if (likedByMe) {
          if (likesCount && likesCount <= 0) {
            return
          }

          try {
            await unlikeReview({
              variables: { reviewId: resourceId },
              optimisticResponse: {
                __typename: "RootMutationType",
                unlikeReview: true,
              },
              update: (cache, { data }) => {
                if (!data?.unlikeReview) {
                  return
                }

                cache.modify({
                  id: cache.identify({ __typename: "Review", id: resourceId }),
                  fields: {
                    likedByMe: () => false,
                    likesCount: (existing: number) => Math.max(0, existing - 1),
                  },
                })
              },
            })
          } catch (e) {
            console.error(e)
          }
        } else {
          try {
            await likeReview({
              variables: { reviewId: resourceId },
              optimisticResponse: {
                __typename: "RootMutationType",
                likeReview: true,
              },
              update: (cache, { data }) => {
                if (!data?.likeReview) {
                  return
                }

                cache.modify({
                  id: cache.identify({ __typename: "Review", id: resourceId }),
                  fields: {
                    likedByMe: () => true,
                    likesCount: (existing: number) => existing + 1,
                  },
                })
              },
            })
          } catch (e) {
            console.error(e)
          }
        }
      } else if (resourceType === "reviewComment") {
        // const { likedByMe, likesCount } = readReviewCommentFromCache()

        if (likedByMe) {
          if (likesCount && likesCount <= 0) {
            return
          }
          await unlikeReviewComment({
            variables: { reviewCommentId: resourceId },
            optimisticResponse: {
              unlikeReviewComment: true,
            },

            update: (cache, { data }) => {
              if (!data?.unlikeReviewComment) {
                return
              }

              cache.modify({
                id: cache.identify({
                  __typename: "ReviewComment",
                  id: resourceId,
                }),
                fields: {
                  likedByMe: () => false,
                  likesCount: (existing: number) => Math.max(0, existing - 1),
                },
              })
            },
          })
        } else {
          await likeReviewComment({
            variables: { reviewCommentId: resourceId },
            optimisticResponse: {
              likeReviewComment: true,
            },
            update: (cache, { data }) => {
              if (!data?.likeReviewComment) {
                return
              }

              cache.modify({
                id: cache.identify({
                  __typename: "ReviewComment",
                  id: resourceId,
                }),
                fields: {
                  likedByMe: () => true,
                  likesCount: (existingLikesCount: number) => {
                    return existingLikesCount + 1
                  },
                },
              })
            },
          })
        }
      } else if (resourceType === "list") {
        // const { likedByMe, likesCount } = readReviewFromCache()

        if (likedByMe) {
          if (likesCount && likesCount <= 0) {
            return
          }

          try {
            await unlikeList({
              variables: { listId: resourceId },
              optimisticResponse: {
                __typename: "RootMutationType",
                unlikeList: true,
              },
              update: (cache, { data }) => {
                if (!data?.unlikeList) {
                  return
                }

                cache.modify({
                  id: cache.identify({ __typename: "List", id: resourceId }),
                  fields: {
                    likedByMe: () => false,
                    likesCount: (existing: number) => Math.max(0, existing - 1),
                  },
                })
              },
            })
          } catch (e) {
            console.error(e)
          }
        } else {
          try {
            await likeList({
              variables: { listId: resourceId },
              optimisticResponse: {
                __typename: "RootMutationType",
                likeList: true,
              },
              update: (cache, { data }) => {
                if (!data?.likeList) {
                  return
                }

                cache.modify({
                  id: cache.identify({ __typename: "List", id: resourceId }),
                  fields: {
                    likedByMe: () => true,
                    likesCount: (existing: number) => existing + 1,
                  },
                })
              },
            })
          } catch (e) {
            console.error(e)
          }
        }
      } else if (resourceType === "listComment") {
        // const { likedByMe, likesCount } = readReviewCommentFromCache()

        if (likedByMe) {
          if (likesCount && likesCount <= 0) {
            return
          }
          await unlikeListComment({
            variables: { listCommentId: resourceId },
            optimisticResponse: {
              unlikeListComment: true,
            },

            update: (cache, { data }) => {
              if (!data?.unlikeListComment) {
                return
              }

              cache.modify({
                id: cache.identify({
                  __typename: "ListComment",
                  id: resourceId,
                }),
                fields: {
                  likedByMe: () => false,
                  likesCount: (existing: number) => Math.max(0, existing - 1),
                },
              })
            },
          })
        } else {
          await likeListComment({
            variables: { listCommentId: resourceId },
            optimisticResponse: {
              likeListComment: true,
            },
            update: (cache, { data }) => {
              if (!data?.likeListComment) {
                return
              }

              cache.modify({
                id: cache.identify({
                  __typename: "ListComment",
                  id: resourceId,
                }),
                fields: {
                  likedByMe: () => true,
                  likesCount: (existingLikesCount: number) => {
                    return existingLikesCount + 1
                  },
                },
              })
            },
          })
        }
      } else {
        throw new Error(`Unsupported resourceType: ${resourceType}`)
      }
    } catch (error) {
      console.error("Error toggling like:", error)
    }
  }

  return { toggleLike }
}
