// ReviewReadMore.tsx
"use client";

import React, { useState, useMemo, useRef } from "react";
import { getHtmlContent, getTruncatedHtml } from "@/utils/readMoreUtils";
import { useResponsive } from "@/hooks/useResponsive";
import { cn } from "@/lib/utils";
import { Review } from "@/graphql/generated/types";
interface ReviewReadMoreProps {
  isSpoiler?: boolean | null;
  content: Review["content"]; // Tiptap JSON content
  mobileLength?: number;
  desktopLength?: number;
  className?: string;
  // Optionally, precomputed values from the server:
  preTruncatedHtml?: string;
  preFullHtml?: string;
  expanded?: boolean;
}
const ReviewReadMore: React.FC<ReviewReadMoreProps> = ({
  isSpoiler,
  content,
  mobileLength = 330,
  desktopLength = 428,
  className,
  preTruncatedHtml,
  preFullHtml,
  expanded = false
}) => {
  const {
    isTablet,
    isMobile
  } = useResponsive();
  const displayLength = isTablet || isMobile ? mobileLength : desktopLength;
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [hideSpoiler, setHideSpoiler] = React.useState(true);

  // Convert the Tiptap JSON content to HTML.
  const htmlContent = getHtmlContent(content);

  // Compute the truncated version.
  const truncatedResult = useMemo(() => preTruncatedHtml ? {
    html: preTruncatedHtml,
    truncated: true
  } : getTruncatedHtml(htmlContent, displayLength), [htmlContent, displayLength, preTruncatedHtml]);
  // Expanded view is the full HTML.
  const expandedHtml = preFullHtml ? preFullHtml : htmlContent;
  const handleReadMoreClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isTablet || isMobile) {
      setIsExpanded(true);
      return;
    }
    const target = e.target as HTMLElement;
    if (target.classList.contains("read-more")) {
      setIsExpanded(true);
    }
  };
  const htmlToRender = isExpanded ? expandedHtml : truncatedResult.html;
  const containerRef = useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const revealSpoiler = (el: HTMLElement) => {
      if (el.classList.contains("revealed")) return;
      el.classList.add("revealed");
      el.setAttribute("aria-pressed", "true");
    };
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const spoilerEl = target.closest("[data-spoiler]");
      if (!spoilerEl) return;
      e.preventDefault();
      revealSpoiler(spoilerEl as HTMLElement);
    };
    const handleKeydown = (e: KeyboardEvent) => {
      const active = document.activeElement as HTMLElement;
      if (!active || !active.hasAttribute("data-spoiler")) return;
      if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
        e.preventDefault();
        revealSpoiler(active);
      }
    };
    container.addEventListener("click", handleClick);
    container.addEventListener("keydown", handleKeydown);
    return () => {
      container.removeEventListener("click", handleClick);
      container.removeEventListener("keydown", handleKeydown);
    };
  }, []);
  return <div ref={containerRef} data-sentry-component="ReviewReadMore" data-sentry-source-file="ReviewReadMore.tsx">
      {isSpoiler && hideSpoiler ? <div onClick={() => setHideSpoiler(false)} className="my-2 sm:my-4 bg-white/[4%] w-fit cursor-pointer py-1.5 px-[9px] sm:py-2 sm:px-2 rounded-[4px] border border-white/[4%] sm:border-white/20">
          <p className="text-xs font-medium sm:text-sm sm:font-normal text-[#e6e6fa]/90">
            This review has spoilers—{" "}
            <span className="text-[#7187e1] font-medium">
              dive in if you&apos;re prepared!
            </span>
          </p>
        </div> : <div onClick={handleReadMoreClick} className={cn("review-content text-sm font-normal whitespace-pre-line sm:text-base lg:leading-[22px] text-[#E6E6FA]/90", "prose prose-invert prose-p:my-3 prose-ul:my-0 prose-ol:my-0 prose-li:my-0.5 text-base font-normal prose-p:leading-[22px] max-w-none [&_ol]:list-decimal [&_ul]:list-disc",
    // Add prose classes for styling

    className)} style={{
      wordWrap: "break-word",
      wordBreak: "break-word",
      overflowWrap: "break-word"
    }} dangerouslySetInnerHTML={{
      __html: htmlToRender
    }} />}
    </div>;
};
export default ReviewReadMore;