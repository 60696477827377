// // Spoiler.ts
import { Mark, mergeAttributes } from "@tiptap/core"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    spoiler: {
      /**
       * Toggle spoiler formatting on selected text.
       */
      toggleSpoiler: () => ReturnType
    }
  }
}

const Spoiler = Mark.create({
  name: "spoiler",
  addOptions() {
    return {
      HTMLAttributes: {
        role: "button",
        tabindex: "0",
        "aria-label": "Reveal spoiler content",
        "aria-pressed": "false",
        class: "editor-spoiler",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "span[data-spoiler]",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const defaultEditorClass = this.options.HTMLAttributes?.class || ""
    const combinedClass = ["spoiler", defaultEditorClass]
      .filter(Boolean)
      .join(" ")

    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        "data-spoiler": "",
        class: combinedClass,
      }),
      0,
    ]
  },

  addCommands() {
    return {
      toggleSpoiler:
        () =>
        ({ state, editor, chain }) => {
          // If any spoilerBlock is active in the current selection...
          if (editor.isActive("spoilerBlock")) {
            const { from, to } = state.selection
            let extendedFrom = from
            let extendedTo = to

            const $from = state.doc.resolve(extendedFrom)
            const $to = state.doc.resolve(extendedTo)

            if (!$from.parent.isTextblock) {
              extendedFrom = $from.after() // jump into the following textblock
            }
            if (!$to.parent.isTextblock) {
              extendedTo = $to.before() // jump into the preceding textblock
            }

            // Look for any spoilerBlock nodes within the current selection.
            state.doc.nodesBetween(from, to, (node, pos) => {
              if (node.type.name === "spoilerBlock") {
                extendedFrom = Math.min(extendedFrom, pos)
                extendedTo = Math.max(extendedTo, pos + node.nodeSize)
              }
            })

            // Extend upward: if the node immediately before the current extended range is a non-empty spoilerBlock, include it.
            while (extendedFrom > 0) {
              const $pos = state.doc.resolve(extendedFrom)
              const prev = $pos.nodeBefore
              if (
                prev &&
                prev.type.name === "spoilerBlock" &&
                prev.textContent.trim() !== ""
              ) {
                extendedFrom -= prev.nodeSize
              } else {
                break
              }
            }

            // Extend downward: if the node immediately after the extended range is a non-empty spoilerBlock, include it.
            while (extendedTo < state.doc.content.size) {
              const $pos = state.doc.resolve(extendedTo)
              const next = $pos.nodeAfter
              if (
                next &&
                next.type.name === "spoilerBlock" &&
                next.textContent.trim() !== ""
              ) {
                extendedTo += next.nodeSize
              } else {
                break
              }
            }

            // Helper: Check if a node is empty (only whitespace).
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isEmpty = (node: any) =>
              !node.textContent || node.textContent.trim() === ""

            // Trim empty nodes from the boundaries.
            const $start = state.doc.resolve(extendedFrom)
            if ($start.nodeAfter && isEmpty($start.nodeAfter)) {
              extendedFrom += $start.nodeAfter.nodeSize
            }
            const $end = state.doc.resolve(extendedTo)
            if ($end.nodeBefore && isEmpty($end.nodeBefore)) {
              extendedTo -= $end.nodeBefore.nodeSize
            }

            // Now, remove the spoilerBlock formatting from the entire extended group.
            return chain()
              .setTextSelection({ from: extendedFrom, to: extendedTo })
              .lift("spoilerBlock")
              .run()
          }

          // Otherwise, just toggle the inline spoiler as normal.
          return chain().toggleMark(this.name).run()
        },
    }
  },
})

export default Spoiler
