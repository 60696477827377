// SpoilerBlock.ts
import { Node, mergeAttributes } from "@tiptap/core"
import { TextSelection } from "prosemirror-state"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    spoilerBlock: {
      /**
       * Toggle spoiler block around the current selection.
       */
      toggleSpoilerBlock: () => ReturnType
    }
  }
}

export const SpoilerBlock = Node.create({
  name: "spoilerBlock",

  group: "block", // treat this as a block-level node
  content: "block+", // can contain one or more block nodes (paras, lists, etc.)
  defining: true, // helps Tiptap keep the block boundary

  addOptions() {
    return {
      HTMLAttributes: {
        role: "button",
        "aria-label": "Reveal spoiler",
        "data-spoiler": "",
        class: "editor-spoiler", // your default styling
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "div[data-spoiler]",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const defaultEditorClass = this.options.HTMLAttributes?.class || ""
    const combinedClass = ["spoiler", defaultEditorClass]
      .filter(Boolean)
      .join(" ")
    return [
      "div",
      mergeAttributes(HTMLAttributes, {
        "data-spoiler": "",
        class: combinedClass,
      }),
      0, // the content
    ]
  },

  addCommands() {
    return {
      toggleSpoilerBlock:
        () =>
        ({ state, dispatch }) => {
          const { from, to } = state.selection
          let tr = state.tr

          // 1. Extend selection boundaries to cover any overlapping spoilerBlock nodes.
          let extendedFrom = from
          let extendedTo = to
          state.doc.nodesBetween(from, to, (node, pos) => {
            if (node.type.name === "spoilerBlock") {
              extendedFrom = Math.min(extendedFrom, pos)
              extendedTo = Math.max(extendedTo, pos + node.nodeSize)
            }
          })

          // 2. Remove any spoilerBlock nodes in the extended range (unwrap them).
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const removals: { pos: number; nodeSize: number; content: any }[] = []
          state.doc.nodesBetween(extendedFrom, extendedTo, (node, pos) => {
            if (node.type.name === "spoilerBlock") {
              removals.push({
                pos,
                nodeSize: node.nodeSize,
                content: node.content,
              })
            }
          })
          // Process removals in reverse order to avoid position conflicts.
          removals
            .sort((a, b) => b.pos - a.pos)
            .forEach(({ pos, nodeSize, content }) => {
              tr = tr.replaceWith(pos, pos + nodeSize, content)
            })

          // 3. Remap extended boundaries.
          let newFrom = tr.mapping.map(extendedFrom)
          let newTo = tr.mapping.map(extendedTo)
          tr.setSelection(TextSelection.create(tr.doc, newFrom, newTo))

          // 4. Remove inline spoiler marks from the range.
          if (state.schema.marks.spoiler) {
            tr.removeMark(newFrom, newTo, state.schema.marks.spoiler)
          }

          // 5. Trim whitespace-only text blocks at the boundaries.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const isEmptyTextBlock = (node: any) =>
            node.isTextblock &&
            (!node.textContent || node.textContent.trim() === "")

          // Trim from the start:
          const $start = tr.doc.resolve(newFrom)
          // If the block node at the start is empty, shift newFrom to the end of that block's parent.
          if ($start.parent && isEmptyTextBlock($start.parent)) {
            // Try to move newFrom forward until we hit a non-empty block.
            newFrom = $start.after()
          }
          // Trim from the end:
          const $end = tr.doc.resolve(newTo)
          if ($end.parent && isEmptyTextBlock($end.parent)) {
            // Move newTo backward to before that empty block.
            newTo = $end.before()
          }
          tr.setSelection(TextSelection.create(tr.doc, newFrom, newTo))

          // 6. Wrap the trimmed range in a single spoilerBlock.
          const spoilerBlockNode = state.schema.nodes["spoilerBlock"]
          if (!spoilerBlockNode) return false
          const $from = tr.doc.resolve(newFrom)
          const $to = tr.doc.resolve(newTo)
          const range = $from.blockRange($to)
          if (range) {
            tr = tr.wrap(range, [{ type: spoilerBlockNode }])
          }

          if (dispatch) {
            dispatch(tr)
          }
          return true
        },
    }
  },
})
